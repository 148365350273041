<template>
  <div>
    <section v-for="(items, marketplace) in itemsByGroup" :to="items" :key="marketplace">
      <h6 class="title mb-1 pink white--text">{{ marketplace }} images:</h6>
      <p v-if="marketplace === 'default' && Object.keys(itemsByGroup).length === 1">
        If default marketplace is the only one marketplace setted, all default images will be send
        to all others marketplaces.
      </p>
      <p v-if="!Object.keys(itemsByGroup).includes('default')">
        Default marketplace has not setted, be aware that some marketplace won't receive your
        pictures.
      </p>
      <v-row v-if="hasMedia" dense>
        <v-col v-for="(media, index) in items" :key="index" cols="2" md="2" sm="2">
          <!-- arbitrary property value to avoid error-->
          <attribute-media v-if="media" :value="media" alias="w237" :noHover="noHover">
            <template v-slot:placeholder v-if="!mini">
              <v-chip class="pa-2 mt-auto" color="pink" label text-color="white">
                <v-icon left>mdi-label</v-icon>
                rank:{{ media.order }}
              </v-chip>
              <v-chip
                v-if="media.editing"
                class="pa-2 mt-auto"
                color="blue"
                label
                text-color="white"
              >
                <v-icon left>mdi-camera-image</v-icon>
                edited
              </v-chip>
              <v-chip
                v-if="media.alias"
                class="pa-2 mt-auto"
                color="green"
                label
                text-color="white"
              >
                <v-icon left>mdi-camera-burst</v-icon>
                alias
              </v-chip>
            </template>
            <template v-slot:actions>
              <dialog-media-carousel :item="media"></dialog-media-carousel>
              <v-btn v-if="!mini && actions"
                fab
                small
                dark
                class="mx-2"
                color="danger"
                @click="editMedia(media.uniq)">
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn v-if="!mini && actions"
                fab
                small
                dark
                class="mx-2"
                color="danger"
                @click="deleteMedia(media.uniq)">
                <v-icon dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </attribute-media>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row>
        <v-col>
          <dialog-media
            v-model="dialogMedia"
            v-if="editedItem.variantId"
            :item="editedItem"
          ></dialog-media>
        </v-col>
      </v-row>
    </section>
    <section v-if="!mini && upload">
      <h6 class="title mb-1 green white--text">To add a new media (image ...)</h6>
      <attribute-marketplaces v-model="marketplace" editing></attribute-marketplaces>
      <common-media-upload
        :variantId="variantId"
        :order="numberOrder"
        :marketplace="marketplace"
        v-on:media:upload="fromUpload"
      ></common-media-upload>
    </section>
  </div>
</template>

<script>
import Mixins from '../../mixins';
import EditMixins from '../../mixins/edit-base';
import { Media, MediaList, MediaManager } from '../../models';
import { EventBus } from '../../helpers/event-bus';

const marketplaceDefault = 'DEFAULT';

export default {
  mixins: [EditMixins, ...Mixins],
  name: 'MediaListView',
  props: {
    variantMedia: {
      type: Object,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Boolean,
      default: false,
    },
    variantId: {
      type: [Number, Boolean],
      default: 0,
    },
    hasMedia: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    EventBus.$on('onUpdatingMediaList', (variantMedia) => {
      if (variantMedia && variantMedia.medias.length > 0) {
        const isSame = this.variantId === variantMedia.variantId;
        if (isSame) {
          this.medias = variantMedia.medias;
        } else {
          this.$log.debug(
            `common.medialistview.mounted.on.oncollectionupdate.${this.variantId}.medias.no.updated`,
          );
        }
      }
    });
  },
  data: () => ({
    defaultItem: Media.create({}),
    editedItem: Media.create({}),
    dialogMedia: false,
    marketplace: marketplaceDefault,
    stepUploadMedia: 1,
  }),
  computed: {
    medias: {
      get() {
        let medias = [];
        if (typeof this.variantMedia !== 'undefined' && this.variantMedia.medias.length > 0) {
          medias = [...this.variantMedia.medias];
        }
        return medias.map((media) => {
          if (media instanceof Media) {
            return media;
          }
          // temp fix, I dont know where medias are not filled with Media instance
          return Media.create(media);
        });
      },
      set(medias) {
        this.variantMedia.medias = MediaList.create(medias, { variantId: this.variantId });
      },
    },
    numberOrder() {
      if (typeof this.itemsByGroup[this.marketplace.toLowerCase()] === 'undefined') {
        return 0;
      }
      return this.itemsByGroup[this.marketplace.toLowerCase()].length || 0;
    },
    itemsByGroup() {
      const itemsByGroup = this.medias.reduce((items, media) => {
        if (!media || Object.keys(media).length === 0) {
          return items;
        }
        if (typeof media.original === 'undefined' && typeof media.aliases === 'undefined') {
          return items;
        }
        if (typeof media.marketplace === 'undefined' || media.marketplace === null) {
          // eslint-disable-next-line no-param-reassign
          media.marketplace = 'default';
        }
        if (typeof items[media.marketplace] === 'undefined') {
          // eslint-disable-next-line no-param-reassign
          items[media.marketplace] = [];
        }
        items[media.marketplace].push(media);
        return items;
      }, {});
      Object.keys(itemsByGroup).forEach((marketplace) => {
        itemsByGroup[marketplace].sort((a, b) => a.order - b.order);
      });
      return itemsByGroup;
    },
  },
  methods: {
    /**
     * This method allow to initialize default data to all dialog
     * @param {[type]} item [description]
     */
    setDefaultMediaItem(item) {
      this.$log.info('common.mediawistviex.setdefaultmediaitem.started');
      const defaultItem = MediaManager.newObject(item);
      this.defaultItem = defaultItem;
      this.editedItem = defaultItem;
    },
    editMedia(uniqId) {
      this.$log.info('common.medialistview.editmedia.started', uniqId);
      const index = this.variantMedia.medias.findIndex(m => m.uniq === uniqId);
      if (index === -1) {
        throw new TypeError(`common.medialistviex.editmedia.${uniqId}.not.found`);
      }
      this.dialogMedia = true;
      this.editedItem = Media.create(this.variantMedia.medias[index]);
    },
    deleteMedia(uniqId) {
      this.$log.debug('common.medialistview.deletemedia.started', uniqId);
      const index = this.variantMedia.medias.findIndex(m => m.uniq === uniqId);
      if (index === -1) {
        throw new TypeError(`common.medialistviex.deletemedia.${uniqId}.not.found`);
      }
      const media = this.variantMedia.medias[index];
      return this.$store
        .dispatch('media/delete', {
          variantId: this.variantId,
          uniq: media.uniq,
        })
        .then((mediaVariantObj) => {
          this.variantMedia.medias.splice(index, 1);
          this.medias = mediaVariantObj.medias;
          // before emitting the event, update on the server
          return this.$emit('delete:item', media);
        })
        .then(() => EventBus.$emit('onCollectionUpdate'))
        .then(() => this.closeItem())
        .catch(error => this.notificationError({ message: error.message }));
    },

    fromUpload(mediaData) {
      this.$log.debug('common.medialistview.fromupload.started', mediaData);
      this.medias = mediaData.medias;
    },
  },
};
</script>

<style lang="css"></style>
